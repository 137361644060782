import React from "react";
import { FaPhoneAlt } from "react-icons/fa";

const ContactCard = ({ title, main, icons }) => {
  return (
    <>
      <div className="flex space-x-4 items-center">
        <button className="bg-primary p-5">{icons}</button>
        <div className="flex flex-col space-y-3">
          <p className="text-lg lg:text-xl">{title}</p>
          <p className="text-primary font-bold nunito text-lg lg:text-xl">
            {main}
          </p>
        </div>
      </div>
    </>
  );
};

export default ContactCard;
