import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import { FaCheck, FaEnvelope, FaPhone } from "react-icons/fa6";
import { FaCloud } from "react-icons/fa";
import { aboutItems, chooseUs, chooseUs2, contact } from "../utils/useful";
import Soft from "../Assets/Images/Soft.jpg";
import AnimatedBar from "../Components/AnimatedBar";
import ChooseUsCard from "../Components/ChooseUsCard";
import Banner from "../Assets/Images/Banner2.jpg";
import ProductCard from "../Components/ProductCard";
import ServiceCard from "../Components/ServiceCard";
import ContactCard from "../Components/ContactCard";
import MapComponent from "../Components/MapComponent";
import LogoLight from "../Assets/Images/logo-light.png";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { productData } from "../utils/product";
import { serviceData } from "../utils/service";
import { FaWhatsapp } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";

const Home = () => {
  const [isDark, setIsDark] = useState(false);
  
  return (
    <div className={`${isDark && "dark"}`}>
      <main className="dark:bg-black" id="home">
        <Navbar setIsDark={setIsDark} isDark={isDark} />
        {/* Hero Starts */}
        <div className="bannerbg w-full h-[100vh] flex justify-center items-center">
          <div className="flex flex-col space-y-5 items-center text-white max-w-[1400px] mx-auto container px-4">
            <FaCloud className="text-5xl" />
            <p className="text-xl uppercase tracking-wide text-center font-bold nunito">
              Empower your future with our
            </p>
            <p className="text-3xl text-center font-extrabold nunito leading-[50px] lg:text-6xl">
              Innovative Digital Solution!
            </p>
          </div>
        </div>
        {/* Hero Ends  */}

        {/* About Starts */}
        <div
          id="about"
          className="my-14 px-4 container mx-auto max-w-[1400px] flex flex-col gap-10 justify-between lg:flex-row lg:items-center  text-black dark:text-white"
        >
          <div className="flex flex-col space-y-14 w-full lg:w-3/5">
            <div className="flex flex-col space-y-3">
              <p className="text-2xl font-extrabold nunito text-primary">
                ABOUT US
              </p>
              <div className="flex flex-col space-y-2">
                <p className="text-3xl nunito font-extrabold leading-[50px] lg:text-4xl">
                  The Best IT Solution Company With a Decade of Experience
                </p>
                <AnimatedBar />
              </div>
            </div>

            <div className="flex flex-col space-y-4">
              <p className=" opacity-85">
                In today's fast-paced world, businesses must embrace digital
                transformation to stay ahead of the competition. That's where
                Revbixs comes in, we offer a comprehensive range of digital
                solutions that are designed to help businesses thrive in a
                rapidly evolving digital landscape.
              </p>
              <div className="flex flex-col space-y-4">
                {aboutItems.map((item, index) => (
                  <div key={index} className="flex space-x-4 items-center">
                    <FaCheck className="text-2xl text-primary" />
                    <p className="text-xl">{item.title}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full lg:w-2/5">
            <img loading="lazy" src={Soft} alt="" className="object-cover w-full h-96" />
          </div>
        </div>
        {/* About Ends  */}
        {/* Compel Starts */}
        <section
          className="mx-auto my-36 px-4 container max-w-[1400px]  text-black dark:text-white"
        >
          <div className="flex flex-col space-y-14 justify-center">
            <div className="flex flex-col space-y-2 w-full xl:w-1/2 mx-auto">
              <p className="text-primary text-center nunito text-xl font-extrabold uppercase">
                Why Choose us
              </p>
              <div className="flex flex-col items-center space-y-2">
                <p className=" text-center nunito text-[32px] font-extrabold leading-[45px]">
                  We strive to propel your business to exponential growth
                </p>
                <div className="">
                  <AnimatedBar />
                </div>
              </div>
            </div>

            <div className="flex-col flex lg:flex-row gap-10">
              <div className="flex flex-col space-y-10 lg:w-4/12">
                {chooseUs.map((item, index) => (
                  <ChooseUsCard key={index} {...item} />
                ))}
              </div>
              <div className="w-full md:w-4/12 h-96">
                <img loading="lazy" src={Banner} alt="" className="w-full object-cover" />
              </div>
              <div className="flex flex-col space-y-10 lg:w-4/12">
                {chooseUs2.map((item, index) => (
                  <ChooseUsCard key={index} {...item} />
                ))}
              </div>
            </div>
          </div>
        </section>
        {/* Compel Ends */}

        {/* Product Section Starts */}
        <section
          className="mx-auto mt-44 mb-10 px-4 container max-w-[1400px]  text-black dark:text-white"
          id="product"
        >
          <div className="flex flex-col space-y-24 justify-center">
            <div className="flex flex-col space-y-2 w-full xl:w-1/2 mx-auto">
              <p className="text-primary text-center nunito text-xl font-extrabold uppercase">
                Our Products
              </p>
              <div className="flex flex-col items-center space-y-2">
                <p className=" text-center nunito text-[32px] font-extrabold leading-[45px]">
                  Revolutionize your business operations with our cutting-edge
                  products for enhanced productivity.
                </p>
                <div className="">
                  <AnimatedBar />
                </div>
              </div>
            </div>

            <div className="flex-col flex flex-wrap sm:flex-row sm:justify-between gap-y-24 gap-x-10">
              {productData.map((item, index) => (
                <ProductCard key={index} {...item} />
              ))}
            </div>
          </div>
        </section>
        {/* Product Section Ends  */}
        {/* Services Section Starts  */}
        <section
          id="service"
          className="mx-auto mt-44 mb-10 px-4 container max-w-[1400px] text-black dark:text-white"
        >
          <div className="flex flex-col space-y-24 justify-center">
            <div className="flex flex-col space-y-2 w-full xl:w-1/2 mx-auto">
              <p className="text-primary text-center nunito text-xl font-extrabold uppercase">
                Our Services
              </p>
              <div className="flex flex-col items-center space-y-2">
                <p className=" text-center nunito text-[32px] font-extrabold leading-[45px]">
                  Custom IT Solutions for Your Successful Business
                </p>
                <div className="">
                  <AnimatedBar />
                </div>
              </div>
            </div>

            <div className="flex-col flex flex-wrap sm:flex-row sm:justify-between gap-10">
              {serviceData.map((item, index) => (
                <ServiceCard key={index} {...item} />
              ))}
              <div className="w-full flex flex-col space-y-6 bg-primary text-white px-5 py-10 md:w-[47%] xl:w-[31%] ">
                <p className="text-xl nunito font-bold">24/7 Help & Support</p>
                <p className="">
                  Need technical support for our products? Our dedicated support
                  team is available 24/7 to assist you with any questions or
                  concerns you may have, your satisfaction is our top priority,
                  and we're committed to providing you with the best possible
                  support experience.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* Services Section Ends  */}
        {/* Contact Section Starts */}
        <section
          id="contact"
          className="mx-auto mt-44 mb-10 px-4 container max-w-[1400px] text-black dark:text-white"
        >
          <div className="flex flex-col space-y-24 justify-center">
            <div className="flex flex-col space-y-2 w-full xl:w-1/2 mx-auto">
              <p className="text-primary text-center nunito text-xl font-extrabold uppercase">
                Contact Us
              </p>
              <div className="flex flex-col items-center space-y-2">
                <p className=" text-center nunito text-[32px] font-extrabold leading-[45px]">
                  If you need to get in touch with us, you can reach out to our
                  customer service department, Feel Free To Contact Us
                </p>
                <div className="">
                  <AnimatedBar />
                </div>
              </div>
            </div>
            <div className="flex flex-col flex-wrap gap-10 justify-between md:flex-row">
              {contact.map((item, index) => (
                <ContactCard key={index} {...item} />
              ))}
            </div>
            <div className="flex-col flex flex-wrap sm:flex-row sm:justify-between gap-10">
              <form
                action=""
                className="flex-col space-y-4 w-full lg:w-1/2 lg:flex-row"
              >
                <div className="flex flex-row justify-between gap-10">
                  <input
                    type="text"
                    className="bg-[#EEF9FF] dark:bg-[#1A2227] border-0 px-4 py-4 w-1/2 focus:outline-0"
                    placeholder="Your Name"
                  />
                  <input
                    type="email"
                    className="bg-[#EEF9FF] dark:bg-[#1A2227] border-0 px-4 py-4 w-1/2 focus:outline-0"
                    placeholder="Your Email"
                  />
                </div>
                <input
                  type="text"
                  className="bg-[#EEF9FF] dark:bg-[#1A2227] border-0 px-4 py-4 w-full focus:outline-0"
                  placeholder="Subject"
                />
                <textarea
                  placeholder="Your Message"
                  className="bg-[#EEF9FF] dark:bg-[#1A2227] border-0 px-4 py-4 w-full h-28 focus:outline-0"
                ></textarea>
                <button className="text-white py-4 bg-primary w-full">
                  Send Message
                </button>
              </form>
              <MapComponent />
            </div>
          </div>
        </section>
        {/* Contact Section Ends */}

        {/* Footer Section Starts */}
        <footer className="w-full bg-black bg-opacity-85 dark:bg-[#1A2227]">
          <div className="container px-4 mx-auto max-w-[1400px] flex flex-col md:flex-row gap-x-28">
            <div className="w-full p-6 py-14 dark:bg-black flex flex-col gap-10 justify-center items-center xl:w-1/4">
              <img loading="lazy" src={LogoLight} alt="" className="w-36 object-cover" />
              <p className="text-white  text-center">
              Revbix ® is a Software development company with over a decade of experience deliverng tailored business solutions to organization.
              </p>
            </div>

            <div className="flex flex-col space-y-6 py-10 text-white  w-full xl:w-1/4">
              <div className="flex flex-col space-y-1">
                <p className="nunito text-[32px] text-white  font-bold leading-[45px]">
                  Get In Touch
                </p>
                <div className="">
                  <AnimatedBar style={`bg-[#262626] bg-opacity-85`} />
                </div>
              </div>

              <div className="flex items-start space-x-3 ">
                <div className="w-6">
                  <FaLocationDot className="text-2xl text-primary" />
                </div>
                <Link to={``}>
                  Toye Building, No 74, Ogunnusi Road, Suite: 213, First floor,
                  Beside FRSC Office, Ojodu-Berger, Ikeja, Lagos
                </Link>
              </div>

              <div className="flex items-start space-x-3 ">
                <div className="w-6">
                  <FaPhone className="text-2xl text-primary" />
                </div>
                <Link to={``}>
                  0701 5229 727, 0814 6971 020,
                  <br /> 0706 6541 117
                </Link>
              </div>

              <div className="flex items-start space-x-3 ">
                <div className="w-6">
                  <FaEnvelope className="text-2xl text-primary" />
                </div>
                <Link to={``}>info@revbixs.com, revbixdev@gmail.com</Link>
              </div>

              <div className="flex space-x-4 items-center">
                <a
                  href="https://wa.me/+2347015229727"
                  className="p-4 bg-primary"
                >
                  <FaWhatsapp className="text-2xl text-white" />
                </a>
              </div>
            </div>

            <div className="flex flex-col space-y-6 py-10 text-white  w-full xl:w-1/4">
              <div className="flex flex-col space-y-1">
                <p className="nunito text-[32px] text-white  font-bold leading-[45px]">
                  Quick Links
                </p>
                <div className="">
                  <AnimatedBar style={`bg-[#262626] bg-opacity-85`} />
                </div>
              </div>

              <div className="flex items-start space-x-3 ">
                <Link to={``}>About</Link>
              </div>

              <div className="flex items-start space-x-3 ">
                <Link to={``}>Product</Link>
              </div>

              <div className="flex items-start space-x-3 ">
                <Link to={``}>Services</Link>
              </div>
            </div>
          </div>
        </footer>
        {/* Footer Section Ends  */}
      </main>
    </div>
  );
};

export default Home;
