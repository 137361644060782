import React from "react";
import { IoMdArrowForward } from "react-icons/io";

const ProductCard = ({ img_path, title, description, route }) => {
  return (
    <>
      <div
        className="w-full  bg-[#EEF9FF] px-5 pb-5 md:w-[47%] xl:w-[30%] dark:bg-[#1A2227]"
      >
        <img
          src={img_path}
          loading="lazy"
          alt=""
          className="-mt-16 w-72 h-80 object-cover rounded-l mx-auto"
        />
        <div className="flex flex-col space-y-6 mt-10">
          <p className="text-xl nunito font-bold line-clamp-1">{title}</p>
          <p className="opacity-85 h-32">{description}</p>
          <div className="w-full flex justify-center">
            <a href={`https://${route}`} target="_blank"  className="mx-auto p-3 bg-primary text-white">
              <IoMdArrowForward className="text-2xl" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
