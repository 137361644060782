import ERPL from "../Assets/Images/ERPL.png";
import ERPP from "../Assets/Images/ERPP.png";
import HMS from "../Assets/Images/HMS.png";
import HospMS from "../Assets/Images/HR.jpg";
import PMS from "../Assets/Images/PMS.png";
import PPOS from "../Assets/Images/PPOS.png";
import RPOS from "../Assets/Images/RPOS.png";
import SPOS from "../Assets/Images/SPOS.png";
import VMS from "../Assets/Images/vms.jpeg";

export const productData = [
  {
    id: 1,
    img_path: ERPL,
    title: "Revbix ERP Lite",
    route: "revbixs.net/demo/erplite",
    description:
      "Revbix ERP Lite, built to integrate and optimize your business processes, streamline operations, and enhance productivity for better decision-making and growth.",
  },
  {
    id: 2,
    img_path: ERPP,
    title: "Revbix ERP Pro",
    route: "",
    description:
      "Revbix ERP Pro, designed to seamlessly integrate and elevate your business processes, maximize operational efficiency, and empower smarter decision-making for accelerated growth and success.",
  },
  {
    id: 3,
    img_path: HMS,
    title: "Revbix Hotel Management Software",
    route: "revbixs.net/demo/hotel",
    description:
      "Revbix Hotel Management software, designed to streamline bookings, manage guest check-ins, track room availability, handle billing, and enhance guest satisfaction for a more efficient hotel experience.",
  },
  {
    id: 4,
    img_path: RPOS,
    title: "Revbix Restaurant POS",
    route: "revbixs.net/demo/restbar",
    description:
      "Revbix Restaurant POS software, designed to optimize order processing, manage inventory, and enhance customer service for a smoother dining experience.",
  },
  {
    id: 5,
    img_path: PPOS,
    title: "Revbix Pharmacy POS",
    route: "revbixs.net/demo/pharmacy",
    description:
      "Revbix Pharmacy POS software, designed to enhance sales, manage inventory, and ensure accurate prescription handling for enhanced efficiency and customer service.",
  },
  {
    id: 6,
    img_path: SPOS,
    title: "Revbix Supermarket POS",
    route: " revbixs.net/demo/supermarket",
    description:
      "Revbix Supermarket POS software, designed to simplify sales transactions, manage inventory, and improve customer service for a more efficient shopping experience.",
  },
  {
    id: 7,
    img_path: PMS,
    title: "Revbix Petrol Station Management Software",
    route: "",
    description:
      "Revbix Petrol Station Management Software, tailored to simplify fuel sales, track inventory, and enhance operational efficiency for a seamless customer experience.",
  },

  {
    id: 8,
    img_path: VMS,
    title: "Revbix Visitor Management Software",
    route: "revbixs.net/demo/vms",
    description:
      "Revbix Visitor Management System is crafted to improve visitor check-ins, enhance security, and provide a seamless experience for all your guests.",
  },
  {
    id: 9,
    img_path: HospMS,
    title: "Revbix HR Management Software",
    route: "",
    description:
      "Revbix HR Management software, designed to optimize employee management, automate payroll, track performance, manage benefits, and enhance HR operations, all in one platform, ensuring a more efficient and productive workplace.",
  },
];
